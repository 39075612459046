.menu {
    /* height: 80px; */
    padding: 18px 0px 18px 0px;
    /* background: rgba(28, 28, 28, 0.9); */
    /* background:#1C1C1C; */
}
main{
    margin-top: 100px;
}

.mobile-navbar-collapse .search-icon {
    top: 4px !important;
}
nav{
    transition: 0.5s ease-in-out;
}

.custom-link {
    color: #ffffff;
    text-decoration: none;
}
#header{
    position: fixed;
    left:0;
    width:100%;
    top:0;
    transition: 0.5s ease-in-out;
    /* background-color: #000; */
    transition: 0.5s ease-in-out;
}
.navbar.active{
    background-color: #000;
    transition: 0.5s ease-in-out;
}

.navbar .nav-item .nav-link:hover,
.navbar .nav-item .nav-link:focus,
.custom-link:hover,
.custom-link:focus {
    color: #F22E62 !important;
}

/* .navbar .navbar-brand img:hover,
.navbar .navbar-brand img:focus {
    filter: invert(42%) sepia(93%) saturate(5577%) hue-rotate(328deg) brightness(98%) contrast(93%);
} */

.navbar-light .navbar-brand img{
    margin-right: 9px;
    transition: 0.5s ease-in-out;
}
.navbar-light .navbar-brand:hover svg path{
    transition: 0.5s ease-in-out;
}
.navbar-light .navbar-brand:hover svg path,
.navbar-light .navbar-brand:hover svg path{
    fill:#F22E62;
    transition: 0.5s ease-in-out;
}
.navbar-light .navbar-brand.active svg path{
    fill:#F22E62;
}


/* 
.row-flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
} */

.logo-text {
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 22px;
    /* font-family: Nunito Sans; */
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 100%;
    text-transform: uppercase;
    color: #ffffff;
}

.color-theme {
    /* color: #C51254; */
    color: #F22E62;
}

.search-box {
    /* flex: none;
    order: 1;
    flex-grow: 0; 
    margin: 0px 22px;*/
    position: relative;
}

.search-box input {
    background: rgba(255, 255, 255, 0.06);
    border-radius: 30px;
    border: none;
    /* background: #1C1C1C; 
    border: 2px solid #3D3D3D;*/
    box-sizing: border-box;
    border-radius: 50px;
    height: 44px;
    width: 249px;
    color: #fff;
    padding: 0px 0px 0px 15px;
}

.search-box input[type="text"]::placeholder {
    font-size: 14px;
    color: #A3A3A3;
    /* font-family: Nunito Sans !important; */
    font-weight: 400;
}


/* Header css */

.navbar-brand {
    font-weight: 800;
    margin-right: 22px !important;
}

.navbar-brand i {
    font-style: normal;
}

.navbar-brand span.color-theme {
    font-weight: 800;
    margin-right: 1px;
}

.search-box.mx-3 {
    margin: 0 !important;
}

ul.navbar-nav.me-auto {
    margin-left: 10px;
}

.search-box input:focus-visible {
    outline: none !important;
}

.search-icon {
    position: absolute;
    right: 5px;
    /* top: 4px; */
    top: -20px;
    padding: 10px;
    /* right: 16px;
    top: 14px; */
    /* top: -43px;
    background: none !important;
    border: none !important; */
}


/* 
.menu-links-row{
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px 22px;
} */

.nav-link {
    /* font-family: Nunito Sans; */
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF !important;
    text-decoration: none;
    margin-right: 0;
    padding-right: 11px !important;
    padding-left: 11px !important;
}


/* 
.header-buttons{
    flex: none;
    order: 3;
    flex-grow: 0;
    margin: 0px 22px;
} */

.theame-btn {
    margin: 0px 10px;
    padding: 7px 23px;
    text-align: center;
    font-weight: 800;
    text-decoration: none;
    /*text-transform: uppercase;*/
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    display: block;
    border-radius: 30px;
}

.create {
    background: #F22E62;
    border: none !important;
}

.create-1 {
    background: linear-gradient(135deg, #AA076B 0%, #61045F 100%);
    border: none !important;
}

.create-1:hover {
    background: #1c1f23;
}

.create:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.create-link {
    background: #C51254;
    border-radius: 50px;
    padding: 0;
    text-decoration: none;
    color: white !important;
    font-weight: 400;
    font-size: 16px;
    margin-right: 0;
    width: 98px !important;
    display: inline-block;
    height: 44px;
    line-height: 44px;
    text-align: center;
}

ul.navbar-nav.me-auto+ul.navbar-nav {
    margin-left: 0;
    margin-right: 0 !important;
    display: flex;
    align-items: center;
}

.login-link {
    border: 1px solid #3E3E3E;
    border-radius: 30px;
    /* border: 2px solid #3D3D3D; */
    box-sizing: border-box;
    /* border-radius: 50px; */
    padding: 0;
    text-decoration: none;
    color: white !important;
    font-weight: 400;
    font-size: 16px;
    width: 98px;
    display: inline-block;
    height: 38px;
    line-height: 38px;
    text-align: center;
    transition: 0.5s;
    cursor: pointer;
}

.login-link:hover {
    background: #F22E62;
    color: #FFF;
}

ul.navbar-nav.me-auto+ul.navbar-nav a {
    margin-right: 9px;
}

.toggle-link {
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    padding: 13px;
    text-decoration: none;
    border-radius: 50%;
}

.height-width {
    height: 44px;
    width: 44px;
}

.toggle-link img {
    vertical-align: middle;
}

.border-bottom-2 {
    border-bottom: 2px solid #3D3D3D;
}

.border-bottom-1 {
    border-bottom: 1px solid #3D3D3D;
}

.create-signin-btn {
    align-items: baseline;
}

.d-menu {
    background: #180018;
    mix-blend-mode: normal;
    border: 1px solid #4C3C4C;
    border-radius: 16px;
    width: 230px;
}

.d-menu .dropdown-item:focus,
.d-menu .dropdown-item:hover,
.d-menu .dropdown-item.active,  .d-menu .dropdown-item:active {
    color: #F22E62 !important;
    background-color: #e9ecef !important;
}


/* 
.create-signin-btn li.nav-item a {
    margin-right: 9px;
    margin-left: 9px;
} */

ul.navbar-nav.me-auto.mb-2.mb-lg-0.create-signin-btn {
    margin: 0;
}


/* about page css */

.cofund_team_section {
    text-align: center;
}


/* 
.cofund_team_section h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 50px;
} */

.cofund_team_section h3.team_title {
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    margin: 15px 0 0;
}

.cofund_team_section .team_boxes {
    /* border: 2px solid #3D3D3D; */
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 320px;
    /* padding: 30px 20px; */
}

.cofund_team_section h5.team_profile {
    font-size: 26px;
    line-height: 120%;
    margin: 0;
    font-weight: 700;
}

.cofund_team_section .team_boxes img {
    width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 50%;
    /* border: 5px solid #FFFFFF; */
    box-sizing: border-box;
}

.advisory_team_section h2,
.developer_section h2,
.team_section h2 {
    margin-top: 80px;
    margin-bottom: 50px;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
}

.advisory_team_section .team_boxes,
.developer_section .team_boxes,
.team_section .team_boxes {
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 30px 20px;
    text-align: center;
}

.team_section h3.team_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    /* text-transform: uppercase; */
}

.team_section h5.team_profile {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    /* text-transform: uppercase; */
}

.developer_section .team_boxes {
    padding: 30px 10px;
}

.developer_section .team_boxes h3.team_title {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
}

.advisory_team_section .row .col-md-3 {
    flex: 0 0 auto;
    width: 16.5%;
    padding: 0 5px;
}

.advisory_team_section .row .col-md-3 .team_boxes h3.team_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    /* text-transform: uppercase; */
}

.advisory_team_section .row .col-md-3 .team_boxes h5.team_profile {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;
    margin-bottom: 0;
}

.advisory_team_section .team_boxes {
    padding: 20px 10px;
    min-height: 120px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.advisory_team_section {
    margin-bottom: 70px;
}

.advisory_team_section h2,
.team_section h2 {
    color: #D4145A;
}

.advisory_team_section .row>.col-md-3:nth-child(even) h3.team_title,
.developer_section .row>.col-md-2:nth-child(even) h3.team_title,
.team_section .row>.col-md-3:nth-child(odd) h3.team_title,
.cofund_team_section .row>.col-md-4:nth-child(2) h3.team_title {
    color: #D4145A;
}

@media screen and (max-width:1200px) {
    .search-box input {
        width: 210px;
    }
    .nav-link {
        font-size: 16px;
        padding-right: 7px !important;
        padding-left: 7px !important;
    }
    .title {
        font-size: 28px;
    }
    .slide-desc {
        font-weight: 600;
        font-size: 18px;
        line-height: 24.8px;
    }
    .sticky-top .container.ps-lg-0 {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .create-link {
        font-size: 16px;
        width: 85px !important;
        height: 40px;
        line-height: 40px;
    }
    .login-link {
        border: 1px solid #3D3D3D;
        font-size: 16px;
        /* width: 85px;
    height: 40px; */
        line-height: 37px;
    }
    .right-icon,
    .left-icon {
        display: none;
    }
    .mt-60 {
        margin-top: 40px !important;
    }
    .navbar-brand i {
        display: none;
    }
    .search-box.mx-3.desk-none.tab-block {
        margin-right: 9px !important;
    }
    .cofund_team_section .team_boxes img {
        width: 150px;
        height: 150px;
        /* border: 2px solid #FFFFFF; */
    }
    .cofund_team_section .team_boxes {
        min-height: 280px;
        /* padding: 20px 15px; */
    }
}

@media screen and (max-width:992px) {
    .create-1-mobile,
    .create-mobile {
        box-sizing: border-box;
        padding: 10px 13px 13px 13px;
        border-radius: 50%;
        width: 48px;
        height: 48px;
    }
    .desk-none.d-block-992 {
        display: block !important;
    }
    .desk-none.d-block-992 {
        display: block !important;
        /* border: 2px solid #3D3D3D; */
        box-sizing: border-box;
        padding: 0;
        text-decoration: none;
        border-radius: 50%;
        height: 44px;
        width: 44px;
        text-align: center;
        margin-left: 9px;
        margin-right: 0;
    }
    .desk-none.d-block-992 span.navbar-toggler-icon {
        margin: 0;
        padding: 0;
        color: #fff;
        filter: invert(100) contrast(1);
        background-size: 25px;
    }
}

@media screen and (max-width:768px) {
    .sticky-top .container.ps-lg-0 {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    .search-box .abs-serch-input {
        display: none;
    }
    .search-box .mobile_serch-bar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        padding-top: 6px;
        padding-right: 10px;
    }
    .search-box .mobile_serch-bar img.search-icon {
        position: static;
    }
    .cofund_team_section .col-md-4 {
        max-width: 50%;
        margin-bottom: 20px;
    }
    .team_section .col-md-3 {
        width: 50%;
        margin-bottom: 20px;
    }
    .developer_section .col-md-2 {
        width: 33.33%;
        margin-bottom: 20px;
    }
    .advisory_team_section .row .col-md-3 {
        max-width: 25%;
        width: 100%;
        margin-bottom: 20px;
        padding: 0 10px;
    }
    .advisory_team_section h2,
    .developer_section h2,
    .team_section h2 {
        margin-top: 40px;
        margin-bottom: 30px;
        font-size: 24px;
    }
    .advisory_team_section .row .col-md-3,
    .developer_section .col-md-2 {
        width: 50%;
        max-width: 50%;
        margin-bottom: 15px;
    }
    .advisory_team_section .team_boxes {
        padding: 15px 10px;
        min-height: 100px;
    }
    .developer_section .team_boxes {
        padding: 20px 5px;
    }
}

@media screen and (max-width:580px) {
    .create-signin-btn a.create-link {
        display: none;
    }
    .team_section .col-md-3,
    .cofund_team_section .col-md-4 {
        max-width: 100%;
        width: 100%;
        margin-bottom: 15px;
    }
    .advisory_team_section .team_boxes,
    .developer_section .team_boxes,
    .team_section .team_boxes {
        border: 1px solid #3D3D3D;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px 8px;
        text-align: center;
    }
}

@media screen and (max-width:414px) {
    .create {
        margin: 0px;
        padding: 8px 10px;
        font-size: 0.8rem;
    }
    .login-link {
        font-size: 0.8rem;
        width: 60px;
        height: 40px;
        margin-left: 5px !important;
        margin-right: 5px !important;
    }
    .mobile_serch-bar {
        width: 40px !important;
        height: 40px !important;
        line-height: 36px !important;
    }
    .desk-none.d-block-992 {
        height: 40px !important;
        width: 40px !important;
        margin-left: 8px;
    }
    .sticky-top .container.ps-lg-0 {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .logo {
        width: 47px;
        height: 40px;
    }
}