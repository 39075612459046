/* .bg-users{
    background: url('../images/users/wallpaper.jpg');
} */

.height-240 {
    height: 240px;
    overflow: hidden;
}

.height-200 {
    height: 200px;
    overflow: hidden;
}

.height-106 {
    height: 106px;
}

.max-width-255 {
    max-width: 255px !important;
    width: 100%;
    /* height: 106px; */
    /* margin-left: auto;
    margin-right: auto; */
}

.avtar-position {
    position: absolute;
    top: 140px;
    border-radius: 50%;
    border: 2px solid #000000;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
}

.avtar-position1 {
    top: 140px;
    border-radius: 50%;
    border: 2px solid #000000;
    /* margin-left: auto;
    margin-right: auto;
    text-align: center; */
}

.copy-btn {
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    border-radius: 50px;
    width: 264px;
    height: 44px !important;
    /* padding: 10px; */
    font-size: 16px;
    padding: 6px 10px 10px 10px;
}

.auther-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #C4C4C4;
}

.follow-btn {
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    border-radius: 50px;
    color: #FFFFFF;
    width: 83px;
    height: 36px;
    line-height: 0;
}

.up-btn,
.more-btn {
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    border-radius: 50%;
    line-height: 0;
    width: 36px;
    height: 36px;
}

.up-btn {
    padding-top: 3px;
    padding-left: 9px;
}

.more-btn {
    padding-top: 4px;
    padding-left: 8px;
}

.tabs1-links button {
    background: none !important;
    border: none !important;
    padding: 30px;
}

.tabs1-links .nav-tabs {
    border-bottom: 1px solid #4C3C4C;
}

.tabs1-links .nav-tabs .nav-link.active {
    border-bottom: 2px solid #FFFFFF !important;
}

.tabs-links .nav-tabs,
.tabs-links .nav-pills {
    border: 1px solid #4C3C4C;
    border-radius: 16px;
    height: 84px;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0 120px 60px;
    display: flex;
    justify-content: space-between;
    /*justify-content: space-between;
    overflow: hidden; */
}

.tabs-links .nav-tabs .nav-item,
.tabs-links .nav-pills .nav-item {
    /* line-height: 84px; */
    /* margin-bottom: 15px; */
    /* flex: 100%; */
    flex: 1;
    border-right: 1px solid #4C3C4C!important;
}

.tabs-links .nav-tabs .nav-item:last-child,
.tabs-links .nav-pills .nav-item:last-child {
    border: 0!important;
}

.tabs-links .nav-tabs .nav-link,
.tabs-links .nav-pills .nav-link {
    padding: 0px;
    margin: 0 0px!important;
    height: 84px;
    border: 0!important;
    width: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tabs-links .nav-tabs .nav-link.active,
.tabs-links .nav-tabs .nav-link:hover,
.tabs-links .nav-pills .nav-link.active,
.tabs-links .nav-pills .nav-link:hover {
    background-color: #F22E62 !important;
    border: 0!important;
}

.bg-darkmode.edit-profile .container {
    padding-left: 0;
    padding-right: 0;
}

.product-pages .explore-dot.bg-black {
    border: 2px solid #3D3D3D;
    width: 36px;
    height: 36px;
    line-height: 35px;
    background: #161616 !important;
}

@media screen and (max-width:1025px) {
    .tabs-links .nav-tabs,
    .tabs-links .nav-pills {
        margin: 0 0px 60px !important;
    }
    .bg-users.height-240,
    .bg-profile.height-240 {
        height: 150px;
    }
    img.avtar-position {
        top: 85px;
        width: 120px;
        height: 120px;
        object-fit: contain;
    }
    .edit-profile-pic-input {
        width: 120px;
        height: 120px;
    }
    .avtar-position {
        top: 85px;
    }
    .edit-prifile-pic-text {
        padding-top: 47px;
    }
    .img-hint {
        left: 140px;
    }
    .text-light.text-edit.font-size-32 {
        margin-top: 0;
    }
    .view-collection-on-tab .col-sm-3 {
        width: 33.33%;
    }
    .edit-profile .container .w-25 {
        width: 50% !important;
    }
}

@media screen and (max-width:768px) {
    .view-collection-on-tab .col-sm-3 {
        width: 50%;
    }
    .bg-users.height-240,
    .bg-profile.height-240 {
        height: 90px;
    }
    img.avtar-position {
        top: 40px;
        width: 90px;
        height: 90px;
    }
    .edit-profile-pic-input {
        width: 90px;
        height: 90px;
    }
    .avtar-position {
        top: 40px;
    }
    .edit-prifile-pic-text {
        padding-top: 35px;
        font-size: 12px;
    }
    .img-hint {
        left: 110px;
    }
}

@media screen and (max-width:580px) {
    .edit-profile .container .w-25 {
        width: 75% !important;
        margin-right: 0 !important;
    }
    .bg-darkmode.edit-profile .color-pink {
        font-size: 14px;
        width: 25%;
    }
    .view-collection-on-tab .col-sm-3 {
        width: 100%;
    }
    .bid-mobile-100 .col-sm-6 {
        width: 50%;
    }
}