.table {
    color: #FFFFFF !important;
    border: 1px solid rgb(207, 219, 213, 0.15);
    border-radius: 12px !important;
    border-collapse: separate;
}

tr {
    height: 62px;
    vertical-align: middle;
}

.collection-table .btn-danger {
    background: #C51254;
    border-radius: 50px;
    width: 100px;
    height: 30px;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    padding: 0;
}

.collection-table td img {
    margin-right: 10px;
}

.stats-boxes {
    mix-blend-mode: normal;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
}

.stats-boxes .stats-box {
    min-width: 130px;
}

.border-right-1 {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.near-icon-pos {
    position: absolute;
    left: 10px;
    top: 10px;
}

.col-img-box {
    width: 88px;
    height: 88px;
    /* background-color: linear-gradient(135deg, #EC008C 0%, #FC6767 100%); */
    border-radius: 6px;
    background-size: cover;
    background-repeat: no-repeat;
}

/* kp css start */

.total-price-box{
    border: 1px solid #4C3C4C;
    border-radius: 18px;
    padding:16px;
    margin-top: 16px;
    margin-bottom: 30px;
}
.total-price-box .top-price{
    padding:0px 0px 12px;
    border-bottom: 1px solid #4C3C4C;
    margin-bottom: 16px;
}
.click-for-price p{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 4px;
}
.click-for-price p a{
    color:#F22E62;
    text-decoration: none;
    transition: 0.5s ease-in-out;
}
.click-for-price p a:hover{
    color:#fff;
}
.click-for-price{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.click-for-price span{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
}
.click-for-price span em{
    color:#fff;
    font-style: unset;
}
.switch.btn{
    min-width:40px!important;
    max-width: 40px !important;
    height: 22px;
    border-radius: 12px;
    border: none;
    background: #F22E62;
    min-height: unset !important;
}
.switch.btn.off .switch-group .switch-handle{
    margin-left: 23px;
}
.switch-group .switch-handle{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    padding: 0px;
    left: 0;
    background:#fff;
    margin-left: -23px;
    margin-top: -3px;
    transition: 0.5s ease-in-out;
}
.switch-group span{
    font-size: 0;
}
.switch-group .switch-off{
    background: #F22E62;
}
.switch-group .switch-off:hover{
    background: #F22E62;
}

/* artist page css */

.feature-card{
    padding:15px;
    background-color: #4C3C4C;
    border: 1px solid #4C3C4C;
    border-radius: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 37px;
}
.feature-card .feature-image-box{
    /* width:44%; */
    width:20%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
}
.feature-card .feature-image-box:hover img{
    transform: scale(1.5);
}
.feature-card .feature-doc{
    width:56%;
    padding-left: 18px;
}
.feature-card .feature-image-box img{
    width:100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: .5s ease;
}
.custom-white{
    color:#fff;
}
.feature-drawstring{
    margin-top: 150px;
    margin-bottom: 45px;
}
/* .feature-drawstring .col-md-6{
    padding:0px;
} */
.feature-drawstring h2{
    font-weight: 700;
    font-size: 54px;
    line-height: 66px;
    color:#fff;
    font-family: 'Clash Display';
    margin-bottom: 20px;
}
.feature-card .feature-doc h3{
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
}
.feature-card .feature-doc h4{
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color:#F22E62;
}
.feature-card .feature-doc h4 span{
    color:#fff;
    font-weight: 400;
}
.feature-card .feature-doc p{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.feature-card .feature-doc button{
    font-weight: 700;
    font-size: 12px;
    margin-left: 0px;
    padding: 1px 13px;
}
.feature-card .feature-doc button img{
    margin-left: 7px;
    vertical-align: middle;
}

@media only screen and (max-width:1024px){
    .feature-drawstring{
        margin-top: 70px;
    }
    .feature-drawstring h2{
        font-size: 36px;
        line-height: 45px;
    }
    .feature-card .feature-doc h3 {
        font-size: 18px;
        line-height: 27px;
    }
    .feature-card .feature-doc p {
        font-size: 13px;
        line-height: 18px;
    }
    .feature-card .feature-doc button{
        font-size:10px;
    }
    .feature-card .feature-doc h4{
        font-size: 12px;
        line-height: 18px;
    }
    .feature-card{
        padding:10px;
    }
}


@media only screen and (max-width:768px){
    .click-for-price p,
    .click-for-price span{
        font-size: 10px;
    }
    .total-price-box{
        padding:10px;
    }
    
}
@media only screen and (max-width:480px){
    .feature-card .feature-image-box {
        width: 44%;
        margin: 0 auto 20px;
    }
    .feature-card .feature-doc{
        width:100%;
        padding-left: 0px;
        text-align: center;
    }
    .feature-card .feature-doc button{
        margin-left:auto;
        margin-right:auto;
    }
    .feature-drawstring h2{
        text-align: center;
    }
    .feature-drawstring h2 {
        font-size: 24px;
        line-height: 32px;
    }
    .feature-card{
        margin-bottom: 20px;
    }
    .feature-drawstring{
        margin-top: 30px;
    }
}

/* end kp css */


