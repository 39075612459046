
.banner-text {
    /* font-family: 'Monument Extended'; */
    font-style: normal;
    font-weight: 700;
    font-size: 77px;
    line-height: 87px;
    font-family: 'Clash Display', sans-serif;
}
.automotive-animation.Bannerlanding{
    /* height: 100vh; */
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.animate-content{
    transform: translateY(100px) !important;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s ease-in;
    transition-duration: 0.5;
}
.animate-content.is-reveal{
    transform: translateY(0px) !important;
    visibility: visible;
    opacity: 1;
}
.animate-content.is-reveal.delay3{
    transition-delay: 0.3s;
}
.animate-content.is-reveal.delay5{
    transition-delay: 0.5s;
}
.animate-content.is-reveal.delay7{
    transition-delay: 0.7s;
}
.duration-el8{
    transition-duration: 0.8;
}
.duration-el4{
    transition-duration: 0.4;
}
.transition5{
    transition: 0.5s ease-in;
}
.custom-bridging .banner-text-mid{
    overflow: hidden;
}
.custom-bridging .banner-text-mid img{
    transform: scale(1.4);
    transition: 0.8s ease-in-out;
    transition-duration: 0.8s;
}
.custom-bridging .banner-text-mid.is-reveal img{
    transform: scale(1);    
    transition-delay: 0.9s;
}
.custom-communication .animate-coentnet{
    transform: translateY(-100) !important;
}




.text-dorange {
    color: #F22E62;
}

.font-family-popins {
    /* font-family: 'Poppins' !important; */
    font-style: normal;
    font-weight: 500;
}

.banner-desc {
    font-size: 24px;
    line-height: 36px;
    padding-right:15px;
    position: relative;
}

.line {
    position: absolute;
    right: 30px;
    width: calc(100% - 183px);
    height: 0px;
    border: 1px solid #FFFFFF;
    bottom: 56px;
    opacity: 0;
    visibility: hidden;
    transform-origin: left;
    transform: scaleX(0);
    transition: 0.4s ease-in;
    /* bottom: 60px; */
}
.line.is-reveal{
    opacity: 1;
    visibility: visible;
    transform: scaleX(1);
    transition-delay: 1s;
}

/* start css of client feedback */

.custom-claim-box p{
    font-weight: 500;
}
.custom-claim-box .subscribe-form .col-auto{
    flex: 0 0 100%;
    width:100%;
    margin-top:1rem;
}
.custom-claim-box .subscribe-form .col-auto button{
    margin-left:0px;
    
}
.custom-platform-cards .robo-content.spacing-left-right{
    padding: 0px 15px 30px;
    z-index: 2;
}
.custom-platform-cards .robo-content.spacing-left-right div p{
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
}

.form-title {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
}
.custom-platform-cards .img-hover-zoom:before{
    content: "";
    position: absolute;
    left:0;
    bottom: 0;
    width:100%;
    height: 100%;
    background: linear-gradient(360deg, #000000 -30%, rgba(0, 0, 0, 0.0001) 100%);
    z-index: 1;
}
.custom-utilities .px-3{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.custom-utilities .px-3 > div{
    min-height: 90px;
}
.custom-utilities .text-light span{
    font-weight: 700;
    font-size: 54px;
    line-height: 66px;
    font-family: 'Clash Display', sans-serif;
}
.custom-bridging .banner-text-mid .nft-bridging,
.custom-bridging .banner-text-mid .bridging-wrapper span{
    font-weight: 700;
    font-size: 64px;
    line-height: 79px;
    font-family: 'Clash Display', sans-serif;
}
.custom-bridging .banner-text-mid:before{
    content: "";
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    background: linear-gradient(360deg, #000000 -120%, rgba(0, 0, 0, 0.1) 100%);
    z-index: 1;
}
.custom-bridging .bridging-wrapper{
    z-index: 2;
}
.custom-communication .border-row{
    padding:42px 0px;
    border:none;
    position: relative;
}
.custom-communication .border-row:before{
    content: "";
    position: absolute;
    left: 33px;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/home/border-image.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 91% 100%;
}
.custom-communication .border-row:after{
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 284px);
    height: 4px;
    background-color: #F22E62;
    z-index: -1;
    margin-left: 30px;
}

.custom-communication .abcd-circle,
.custom-communication .e-circle{
    font-family: 'Clash Display', sans-serif;
}
.custom-number-box .number-box{
    font-weight: 700;
    font-size: 42px;
    font-family: 'Clash Display', sans-serif;
}
.custom-number-box .number-box span{
    background: linear-gradient(135deg, #EC008C 0%, #FC6767 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.custom-number-box{
    margin-top:110px;
    margin-bottom: 110px;
}
.custom-music .mu-box{
    padding:110px 80px !important;
}
.custom-music .mu-box .col-sm-8 div{
    font-weight: 700;
    font-size: 54px;
    line-height: 66px;
    font-family: 'Clash Display', sans-serif;
    margin-bottom: 20px;
} 
.custom-music .mu-box .col-sm-8 p{
    margin-bottom: 30px;
}


/* end css of client feedback */

.input-css {
    background: rgba(255, 255, 255, 0.06);
    border-radius: 30px;
    color: #f22e62 !important;
}

.font-size-34 {
    font-size: 34px;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-900 {
    font-weight: 900;
}

.pb-box {
    width: 65px;
    height: 65px;
    border: 1px solid #F22E62;
    border-radius: 10px;
}

.padding-12 {
    padding: 12px;
}

.line-2 {
    position: absolute;
    width: 122px;
    height: 0px;
    border: 1px solid #F22E62;
    /* transform: rotate(90deg); */
    left: 45%;
    transform: rotate(90deg) translate(0%, -45%);
}

.number-box {
    width: 65px;
    height: 65px;
    /* background: linear-gradient(135deg, #EC008C 0%, #FC6767 100%); */
    background: #FFFFFF;
    color: #F22E62;
    border-radius: 10px;
    font-size: 38px;
    font-weight: 900;
}

.number-boxes-bg {
    background: linear-gradient(135deg, #EC008C 0%, #FC6767 100%);
    border-radius: 20px;
}

.robo-content {
    position: absolute;
    /* bottom: 0px; */
    padding: 10px;
    text-align: center;
    top: 50%;
}

.first-box {
    background: #1C1C1C;
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    border-radius: 8px;
}

.first-box-image {
    /* background: url('../images/home/covid_santa.svg'); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 462px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    /* border-radius: 8px; */
    border-left: 2px solid #3D3D3D;
    /* box-sizing: border-box; */
}

.home-banner-bg {
    background-image: url('../imgs/home/bg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    min-height: 400px;
}

.first-img {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.last-img {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.slider-text {
    position: absolute;
    bottom: 18px;
    width: 100%;
    text-align: center;
}

.border-none {
    border: none;
}

.margin-0-auto {
    margin: 0 auto !important;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(360deg, rgb(0 0 0 / 40%) 0%, rgba(0, 0, 0, 0.0001) 100%);
    mix-blend-mode: normal;
}


/* 
.create-link{
    background: #C51254;
    border-radius: 50px;
    padding: 13px 20px 13px 20px;
    text-decoration: none;
    color: white;
    font-weight: 400;
    font-size: 16px;
    margin-right: 1rem;
}


.login-link{
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 11px 20px 11px 20px;
    text-decoration: none;
    color: white;
    font-weight: 400;
    font-size: 16px;
    margin-right: 1rem;
} */

.title {
    font-size: 32px;
    font-weight: bold;
}

.slide-desc {
    font-weight: 600;
    font-size: 24px;
    line-height: 28.8px;
}

.load-more {
    /* background: #C51254;
    border-radius: 50px;
    padding: 0.6em 1em 0.6em 1em;
    text-decoration: none;
    color: white !important;
    font-weight: 700;
    font-size: 16px;
    margin-right: 0;
    text-align: center;
    border: none; */
    background-color: rgba(235, 240, 240, 0.1) !important;
    mix-blend-mode: normal;
    border-radius: 23px !important;
    border: none !important;
}

.load-more:hover,
.load-more:focus {
    background-color: #212529 !important;
}

.load {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.col-name {
    font-size: 12px;
}

.pos-rel {
    position: relative;
}

.short-line {
    position: absolute;
    width: 62px;
    height: 2px;
    background: #7D7D7D;
    bottom: 0px;
}

.long-line {
    width: 268px;
    height: 2px;
    background: #3D3D3D;
}

.bg-img1 {
    /* background: url('../images/home/1.svg'); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    border-radius: 8px;
}

.bg-img2 {
    background: url('../images/home/2.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    border-radius: 8px;
}

.bg-img3 {
    background: url('../images/home/3.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    border-radius: 8px;
}

.bg-img4 {
    background: url('../images/home/4.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    border-radius: 8px;
}

.img-title {
    position: absolute;
    bottom: 0;
    left: 0;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    background: linear-gradient( 180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    width: 100%;
    padding: 15px 18px;
    line-height: 24px;
}

.left-icon {
    position: absolute;
    top: 46%;
    left: 7%;
}

.right-icon {
    position: absolute;
    top: 46%;
    right: 7%;
}

.vertical-align {
    vertical-align: middle;
}

.top-sec-box {
    /* background: #2A2A2A;
    border-radius: 8px; */
    background: linear-gradient(180deg, rgba(180, 180, 180, 0.15) 0%, rgba(69, 69, 69, 0.15) 100%);
    mix-blend-mode: normal;
    border: 1px solid #740562;
    border-radius: 16px;
    max-height: 425px;
    min-height: 425px;
}

.border-radius-16 {
    border-radius: 16px;
}

.numbers {
    font-weight: 800;
    font-size: 20px;
    line-height: 100%;
    color: rgba(255, 255, 255, 0.15);
}

.r-text {
    font-weight: 800;
    font-size: 32px;
    line-height: 100%;
    color: #3B8279;
    background: linear-gradient( 206.57deg, #EFD916 9.03%, #968C5A 92.36%);
    height: 48px;
    width: 48px;
    text-align: center;
    line-height: 48px;
    border-radius: 50%;
    position: relative;
    margin-top: -18px;
    left: 15px;
}

.home_explore .text-light.font-size-18 {
    font-weight: 700;
    /* font-family: Nunito Sans !important; */
}

.home_explore .text-light.font-size-18 .row.pt-2 .col-sm-6 {
    font-size: 16px;
}


/* 
.home_explore .top-sec-box .pt-1 {
    font-size: 16px;
} */

.home_explore {
    margin-bottom: 50px;
}

.top-sec-box .color-gray,
.top-sec-box .collect-name {
    font-weight: 700;
}

.color-gray {
    color: #AEAEAE;
}

.explore-dot {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    text-align: center;
    /* line-height: 1.3; */
}


/* 
.padding-bottom-4{
    padding-bottom: 4px;
} */

.bg-pink {
    background: #BA2C2C;
}

.bg-blue {
    background: #57AAAA;
}

.bg-green {
    background: #6CC018;
}

.bg-black {
    background: #1C1C1C;
}

.bid-btn {
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    border-radius: 50px;
    text-decoration: none;
    border: 2px solid #3D3D3D;
    padding: 7px 15px 7px 15px;
    border-radius: 30px;
    color: #fff;
    font-size: 16px;
}

.bid-btn:hover {
    background: #C51254;
    color: #fff;
}

.heart-btn {
    text-decoration: none;
}

.heart-btn img:hover {
    background-color: #C51254;
}

.home_banner-section {
    margin-top: 30px;
}

.mt-60 {
    margin-top: 60px !important;
}

.home_featured.mt-60 {
    padding: 0;
}

.main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item .item {
    padding: 110px 8%;
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0 !important;
    max-width: 1200px !important;
}

.pos-rel.home_banner-section .alice-carousel {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.home_banner-section .alice-carousel__dots {
    display: none;
}

.pos-rel.home_banner-section .alice-carousel .alice-carousel__wrapper {
    height: auto !important;
}

.pos-rel.home_banner-section .alice-carousel .banner_text_part img {
    max-width: 440px;
    width: 100%;
}

.main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item .item h1 {
    color: #fff;
    font-size: 46px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 0;
}

.pos-rel.home_banner-section .alice-carousel .alice-carousel__next-btn,
.pos-rel.home_banner-section .alice-carousel .alice-carousel__prev-btn {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -20px;
}

.pos-rel.home_banner-section .alice-carousel .alice-carousel__next-btn {
    right: -20px;
    left: unset;
}

.pos-rel.home_banner-section .alice-carousel .alice-carousel__next-btn p.alice-carousel__next-btn-item,
.pos-rel.home_banner-section .alice-carousel .alice-carousel__prev-btn p.alice-carousel__prev-btn-item {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    font-size: 24px;
}

.main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item .item h2 {
    color: #000000;
    text-shadow: 0px 1px 4px rgba(255, 255, 255, 0.25);
    font-size: 46px;
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 0px;
    padding-left: 7%;
    line-height: 1;
}

.procol {
    margin-top: 60px;
}

.procol h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
}

.procol p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0;
    margin: 0 0 25px
}

.procol p:last-child {
    margin-bottom: 0;
}

.problemContent {
    background: #2A2A2A;
    margin-top: 30px;
    padding: 20px 0;
}

.roundCol .row {
    display: grid;
    grid-template-columns: 330px 330px 330px;
    gap: 90px;
}

.rcol {
    width: 330px;
    height: 330px;
    border: 8px solid #C51254;
    text-align: center;
    border-radius: 100%;
    margin: 40px 0 0;
    position: relative;
    padding: 57px 0 0;
}

.rcol.white {
    border-color: #CDCDCD;
    position: relative;
}

.rcol.white:before {
    width: 127px;
    height: 29px;
    content: '';
    position: absolute;
    left: -116px;
    top: 44%;
    background: url(../images/home/round-dot.png);
    z-index: 1;
}

.rcol.white:after {
    width: 127px;
    height: 29px;
    content: '';
    position: absolute;
    right: -116px;
    top: 44%;
    background: url(../images/home/round-dot.png);
    z-index: 1;
}

.rcol p {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    padding: 28px 40px 0;
}

.stepsArea .row {
    display: grid;
    grid-template-columns: 370px 370px 370px;
    gap: 20px;
}

.steps {
    background: #1C1C1C;
    border: 2px solid #3D3D3D;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 30px 0 0;
    text-align: center;
}

.steps h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    color: #FFFFFF;
    background: url(../images/home/pink-border.png) no-repeat center top;
    padding: 42px 30px 34px;
}

.steps.secondgrayborder h2 {
    background: url(../images/home/grayborder.png) no-repeat center top;
}

.plateform {
    margin: 60px 0 0;
}

.plateform h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.plateform .row {
    display: grid;
    grid-template-columns: 270px 270px 270px 270px;
    gap: 30px;
    text-align: center;
}

.platform-box {
    width: 100%;
    min-height: 412px;
    background: url(../images/home/marketplace.png) no-repeat left top;
    border-radius: 8px;
    position: relative;
}

.platform-box:after {
    background: rgba(42, 42, 42, 0.93);
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px;
}

.overlaycolor {
    position: relative;
    z-index: 1;
}

.platform-box:nth-child(2n+2):after {
    background: rgba(197, 18, 84, 0.9);
}

.platform-box:nth-child(2) {
    background: url(../images/home/studio.png) no-repeat left top;
}

.platform-box:nth-child(3) {
    background: url(../images/home/licensing.png) no-repeat left top;
}

.platform-box:nth-child(2) {
    background: url(../images/home/ducation.png) no-repeat left top;
}

.platform-box img {
    padding: 48px 0 38px;
}

.platform-box h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    margin: 0 0 16px;
}

.platform-box p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    color: #FFFFFF;
    padding: 0 16px 0;
    margin: 0;
}

/* about page css start kp */

.about-section-height .about-title-font,
.custom-about-profile h1{
    font-size: 77px;
    line-height: 87px;
}
.about-section-height p{
    font-size: 24px;
    line-height: 36px;
    font-family: 'Poppins' !important;
}
.about-section-height p:not(:last-child){
    margin-bottom: 30px;
}
.about-section-height .text-center{
    max-width: 805px;
}
.custom-about-profile h1{
    margin-bottom: 80px !important;
}
.custom-accordion{
    margin-top: 50px;
}
.custom-accordion .accordion-header button a{
    color:#fff;
    text-decoration: none;
}
.custom-accordion .accordion-header button{
    font-size: 22px !important;
}
.custom-accordion .accordion-body{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: 'Poppins' !important;
    color:#fff;
}
.custom-accordion .accordion-body a{
    color:#fff;
}

/* end desktop css kp */



@media screen and (max-width:1199px) {
    .roundCol .row {
        display: flex;
        grid-template-columns: auto;
        gap: 10px;
    }
    .rcol {
        width: calc(97% / 3);
    }
    .stepsArea .row {
        display: flex;
        grid-template-columns: inherit;
        gap: 20px;
        margin: 0 2%;
    }
    .plateform .row {
        display: flex;
        grid-template-columns: auto;
        gap: 30px;
        text-align: center;
        padding: 0 4%;
    }
    .platform-box {
        min-height: auto;
        background-size: cover !important;
        padding-bottom: 40px;
    }
    .steps img {
        max-width: 100%
    }
    .banner-text{
        font-size: 56px;
        line-height: 70px;
    }
    .banner-desc {
        font-size: 20px;
        line-height: 32px;
        padding-right: 0;
    }
    .custom-claim-box .top-sec-box{
        max-height: 370px;
        min-height: 370px;
    }
    .custom-platform-cards .robo-content.spacing-left-right div p {
        font-size: 13px;
        line-height: 18px;
    }
    .custom-utilities .text-light span{
        font-size: 42px;
        line-height: 52px;
    }
    .custom-bridging .banner-text-mid .nft-bridging, .custom-bridging .banner-text-mid .bridging-wrapper span{
        font-size: 40px;
        line-height: 52px;
    }
    .abcd-circle{
        width: 100px;
        height: 100px;
        font-size:18px !important;
    }
    .custom-communication .e-circle{
        width: 240px;
        height: 105px;
        font-size: 18px;
    }
    /* kp */

    .about-section-height .about-title-font,
    .custom-about-profile h1{
        font-size: 60px !important;
        line-height: 75px;
    }
    .about-section-height p{
        font-size: 20px;
        line-height: 32px;
    }
    .about-section-height .text-center {
        max-width: 673px;
    }
    
}

@media screen and (max-width:1025px) {
    .long-line {
        width: 220px;
    }
    .home_explore .col-sm-3,
    .collection-name-tab-d .col-sm-3 {
        width: 33.33%;
    }
    .r-text {
        margin-top: 2px;
    }
    .main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item {
        padding: 0 20px !important;
    }
    .pos-rel.home_banner-section .alice-carousel .alice-carousel__next-btn,
    .pos-rel.home_banner-section .alice-carousel .alice-carousel__prev-btn {
        display: none;
    }
    .custom-platform-cards .robo-content.spacing-left-right{
        padding: 0px 10px 30px;
    }
    .about-section-height .about-title-font, .custom-about-profile h1 {
        font-size: 42px !important;
        line-height: 52px;
    }
    .about-section-height p {
        font-size: 18px;
        line-height: 26px;
    }
    .cofund_team_section h3.team_title{
        font-size: 24px;
    }
    .cofund_team_section h5.team_profile{
        font-size: 20px;
    }
    
}

@media screen and (max-width:992px) {
    .first-box-image {
        height: 370px;
    }
    .bg-img4,
    .bg-img3,
    .bg-img2,
    .bg-img1 {
        height: 175px;
    }
    .row.first-box .col-sm-6.p-5.pb-3 {
        padding-top: 24px !important;
        padding-left: 25px !important;
        padding-right: 10px !important;
    }
    .r-text {
        margin-top: -16px;
    }
    .banner-text {
        font-size: 40px;
        line-height: 50px;
    }
    .banner-desc {
        font-size: 16px;
        line-height: 24px;
    }
    .theame-btn{
        padding: 7px 15px;
        font-size: 13px;
    }
    .custom-claim-box .top-sec-box {
        max-height: auto;
        min-height: auto;
    }
    .px-5 {
        padding-right: 1rem!important;
        padding-left: 1rem!important;
    }
    .font-size-34 {
        font-size: 32px;
    }
    .font-size-18{
        font-size: 16px !important;
    }
    .custom-bridging .banner-text-mid .nft-bridging, .custom-bridging .banner-text-mid .bridging-wrapper span {
        font-size: 31px;
        line-height: 40px;
    }
    .custom-bridging .banner-text-mid .bridging-wrapper span{
        line-height: 20px;
    }
    .custom-communication .e-circle{
        width:200px;
    }
    .custom-communication .border-row:before{
        left: 0px;
        background-size: 100% 100%;
    }
    .custom-communication .border-row{
        padding: 20px 0px;
    }
    .custom-number-box{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .font-size-24 {
        font-size: 18px;
    }
    .custom-music .mu-box {
        padding: 50px 20px !important;
    }
    .custom-music .mu-box .col-sm-8 div{
        font-size: 40px;
        line-height: 50px;
    }
}

@media screen and (max-width:768px) {
    .line {
        display: none;
    }
    /*.comn-ls{
        display: none;
    }*/
    .container {
        font-size: 16px;
        font-weight: 200;
    }
    .col-p {
        padding: 0.3rem;
    }
    .mis-ad {
        justify-content: left;
    }
    .pos-rel.home_banner-section .alice-carousel .banner_text_part img {
        max-width: 250px;
    }
    .main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item .item h2,
    .main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item .item h1 {
        font-size: 30px;
    }
    .main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item .item {
        padding: 70px 8%;
    }
    .home_banner-section .container>.row .col-sm-5,
    .home_banner-section .container>.row .col-sm-7 {
        width: 100%;
    }
    .home_banner-section .container>.row .col-sm-5 {
        display: flex;
        flex-wrap: wrap;
        padding: 0 30px;
        justify-content: space-between;
    }
    .home_banner-section .container>.row .col-sm-5>.row,
    .home_banner-section .container>.row .col-sm-5>.row .col-sm-6 {
        width: 50%;
        padding: 0 0px !important;
    }
    .home_banner-section .container>.row .col-sm-5>.row .col-sm-6:nth-child(1) {
        padding-right: 10px !important;
    }
    .home_banner-section .container>.row .col-sm-5>.row .col-sm-6:nth-child(2) {
        padding-left: 10px !important;
    }
    .mt-60 {
        margin-top: 20px !important;
    }
    .home_banner-section .first-box .title {
        font-size: 38px;
        line-height: 52px;
    }
    .home_featured .row.pt-2 .col-sm-3 {
        width: 25%;
        margin: 0 0 22px;
    }
    .home-top-collection.mt-60 .row.pt-2 .col-sm-3 {
        width: 50%;
        margin-bottom: 0;
        padding-bottom: 20px !important;
    }
    .home_explore .col-sm-3 {
        width: 50%;
    }
    .home_explore {
        margin-bottom: 30px;
    }
    .home_banner-section .col-sm-7.pe-4 {
        padding: 0px 34px !important;
    }
    .home_banner-section {
        margin-top: 20px;
    }
    .home_banner-section .first-box {
        border: 1px solid #3D3D3D;
        border-radius: 8px;
        flex-direction: column-reverse;
    }
    .first-box-image {
        height: 374px;
        border-left: 2px solid #3D3D3D;
    }
    .row.first-box .col-sm-6.p-5.pb-3 {
        padding: 20px !important;
    }
    .first-box .my-5 a.create-link.active {
        height: 44px;
    }
    .home_banner-section .first-box {
        border: 1px solid #3D3D3D;
        border-radius: 8px;
        flex-direction: row;
        margin-bottom: 20px;
    }
    .home_banner-section .first-box .my-5 {
        margin-top: 30px !important;
        margin-bottom: 30px !important;
    }
    .home_banner-section .col-sm-5 .col-sm-6.mb-4 {
        padding: 0 20px;
    }
    .home_featured .row.pt-2 .col-sm-3 img.img-fluid {
        height: 178px;
        object-fit: cover;
        border-radius: 8px;
    }
    .home_featured {
        padding-left: 20px;
        padding-right: 20px;
    }
    .home-top-collection.mt-60 .row.pt-2 .col-sm-3 .top-sec-box .row.p-3 .col-sm-4 {
        width: 25%;
    }
    .home-top-collection.mt-60 .row.pt-2 .col-sm-3 .top-sec-box .row.p-3 .col-sm-8 {
        width: 75%;
    }
    .home-top-collection.mt-60 a.login-link.active {
        width: 100%;
        margin-top: 15px;
        height: 44px;
        line-height: 42px;
        border: 2px solid #3D3D3D;
    }
    .home_explore .top-sec-box .row.py-2.px-3 .col-sm-4,
    .home_explore .top-sec-box .row.py-2.px-3 .col-sm-8 {
        width: 50%;
    }
    .home_explore .top-sec-box img.w-100 {
        width: 100%;
        max-width: 75%;
        margin: 0 auto;
        display: block;
    }
    .roundCol .row {
        display: flex;
        grid-template-columns: auto;
        gap: 10px;
    }
    .rcol {
        width: calc(96% / 1);
        border-radius: 0;
        margin: 80px 2% 0 2%;
        height: auto;
        padding: 20px 0;
        border-radius: 30px;
    }
    .rcol p {
        padding: 20px 0;
    }
    .rcol.white::before {
        left: 41%;
        top: -69px;
        transform: rotate(90deg);
    }
    .rcol.white::after {
        right: 41%;
        top: auto;
        bottom: -66px;
        transform: rotate(90deg);
    }
    .banner-text {
        font-size: 30px!important;
        line-height: 40px!important;
    }
    .banner-desc {
        font-size: 14px!important;
        line-height: 20px!important;
    }
    .Bannerlanding .theame-btn{
        padding: 7px 10px;
        font-size: 12px;
        margin: 0px 5px;
    }
    .custom-communication .e-circle {
        width: 165px;
        height: 95px;
    }
    .custom-communication .border-row:after{
        margin-left:0px;
    }
    .about-section-height .about-title-font, .custom-about-profile h1 {
        font-size: 32px !important;
        line-height: 42px;
    }
    .about-section-height p {
        font-size: 16px;
        line-height: 26px;
    }
    .custom-about-profile h1 {
        margin-bottom: 30px !important;
    }
    .about-team .team-title{
        font-size: 15px;
        line-height: 20px;
    }
    .about-team .team-subtitle{
        font-size: 14px;
        line-height: 20px;
    }
    
}

@media screen and (max-width:580px) {
    .rcol.white::before,
    .rcol.white::after {
        left: 32%;
    }
    .home_banner-section .first-box {
        flex-direction: column-reverse;
    }
    .home_banner-section .first-box .title {
        font-size: 28px;
        line-height: 33.6px;
    }
    .home_featured .row.pt-2 .col-sm-3,
    .home_banner-section .container>.row .col-sm-5>.row .col-sm-6 {
        width: 50%;
    }
    .home_banner-section .container>.row .col-sm-5>.row {
        width: 100%;
        padding: 0 0px !important;
        margin: 0;
    }
    .home_banner-section .container>.row .col-sm-5 {
        padding: 0 20px;
    }
    .home_banner-section .container>.row .col-sm-5>.row .col-sm-6:nth-child(2) {
        padding-left: 10px !important;
    }
    .home_banner-section .container>.row .col-sm-5>.row .col-sm-6:nth-child(1) {
        padding-right: 10px !important;
        padding-left: 0px !important;
    }
    .home-top-collection.mt-60 .row.pt-2 .col-sm-3 {
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 10px !important;
    }
    .home_explore .col-sm-3 {
        width: 100%;
    }
    .pos-rel.home_banner-section .alice-carousel .banner_text_part img {
        max-width: 200px;
    }
    .main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item .item h2,
    .main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item .item h1 {
        font-size: 20px;
    }
    .main-sec-full ul.alice-carousel__stage li.alice-carousel__stage-item .item {
        padding: 50px 8%;
    }
    .banner-text{
        font-size: 38px;
        line-height: 47px;
        text-align: center;
    }
    .banner-desc{
        font-size: 18px;
        line-height: 27px;
        text-align: center;
    }
    .pb-5 {
        padding-bottom: 2rem!important;
    }
    .pt-4 {
        padding-top: 0.5rem!important;
    }
    .Bannerlanding .d-flex{
        justify-content: center;
        order: 2;
        flex-direction: column;
        align-items: center;
    }
    .Bannerlanding .col-sm-6 img{
        width: 246px;
        height: 246px;
        margin: 0 auto;
        display: block;
    }
    .Bannerlanding .theame-btn{
        padding: 10px 52px;
        font-size: 13px;
        margin: 0px 0px;
        margin-bottom: 16px;
    }
    .custom-claim-box .col-sm-7{
        order:2;
        text-align: center;
        padding-bottom: 40px;
    }
    .font-size-34.res-font-size-28{
        font-size: 32px;
        line-height: 39px;
        margin-bottom: 20px;
    }
    .custom-claim-box p{
        font-size: 16px;
        line-height: 24px;
    }
    .custom-claim-box .subscribe-form .col-auto{
        justify-content: center;
        display: flex;
    }
    .custom-claim-box .theame-btn,
    .custom-music .theame-btn {
        padding: 9px 60px !important;
    }
    .custom-music .theame-btn{
        margin:0 auto;
    }
    .font-size-34{
        text-align: center;
    }
    .font-size-18{
        text-align: center;
        margin-top: 30px;
    }
    .custom-utilities .text-light span{
        font-size: 32px;
        line-height: 39px;
    }
    .custom-utilities .text-light span{
        display: block;
    }
    .custom-utilities .px-3 > div {
        min-height: 60px;
    }
    .d-flex.res-display-grid .px-3{
        margin-bottom: 20px;
    }
    .bridging-wrapper{
        position: relative;
        left: inherit;
        transform: inherit;
        width: 100% !important;
        margin-top: 50px;
    }
    .custom-bridging .banner-text-mid .nft-bridging, .custom-bridging .banner-text-mid .bridging-wrapper span{
        font-size: 32px;
        line-height: 39px;
        width: auto;
    display: inline-block;
    padding:0px 12px;
    }
    .nft-bridging-below .container{
        padding-top: 0 !important;
        padding-bottom: 10px !important;
    }
    .nft-bridging-below .container p:first-of-type{
        margin-top: 0px;
    }
    .abcd-circle{
        width: 45px;
        height: 45px;
        font-size: 8px !important;
    }
    .custom-communication .e-circle {
        width: 94px;
        height: 45px;
        font-size: 8px;
    }
    .custom-communication .border-row {
        padding: 8px 0px;
    }
    .custom-communication .border-row:after{
        width: calc(100% - 140px);
    }
    .custom-communication .col-4 img{
        width:18px;
    }
    .custom-communication .col-4 .my-3{
        margin-top:6px !important;
        margin-bottom: 0px !important;
    }
    .custom-number-box .container{
        padding-top:20px;
    }
    .custom-number-box .number-boxes-bg{
        padding:40px 15px 40px 15px !important;
    }
    .custom-number-box .font-size-24{
        font-size: 24px;
    }
    .custom-number-box .col-sm-4:not(:last-child){
        margin-bottom: 30px;
    }
    footer .col-lg-3{
        justify-content: center;
    }
    .custom-claim-box{
        margin-top: 50px;
        margin-bottom: 30px;
    }
    
}