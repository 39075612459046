/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400&display=swap'); */


/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import 'animate.css'; */
@import url('https://fonts.cdnfonts.com/css/clash-display');
@import './styles/home.css';
@import './styles/user.css';
@import './styles/collection.css';
@import './styles/createcollection.css';

/* @import './styles/header.css';
@import './styles/footer.css'; */


/* @import url('../node_modules/locomotive-scroll/dist/locomotive-scroll.css'); */

body {
    font-family: 'Poppins' !important;
    font-style: normal;
    background-color: #0f000f !important;
    background-image: url('./imgs/home_bg.png') !important;
    background-repeat: no-repeat;
    background-size: cover;
}

html {
    scroll-behavior: smooth;
}

.main-content {
    min-height: 100vh;
}

.white-content {
    background: #FFFFFF !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.logo {
    width: 75px;
    height: 50px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.explore {
    display: flex;
}

.discover {
    cursor: pointer;
}

.custom-dropdown-class button {
    mix-blend-mode: normal;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 18px;
}

.load-col {
    background: #C51254;
    border-radius: 50px;
    padding: 0.5em 0.8em 0.5em 0.8em;
    text-decoration: none;
    color: white !important;
    font-weight: 400;
    font-size: 16px;
    margin-right: 0;
    text-align: center;
    border: none;
}

.color-gradient {
    background: linear-gradient(135deg, #EC008C 0%, #FC6767 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    font-size: 14px;
    font-weight: 700;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.border-top-none {
    border-top: none !important;
}

.font-size-32 {
    font-size: 32px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-18 {
    font-size: 18px !important;
    font-weight: 700;
}

.font-size-16 {
    font-size: 16px !important;
}

.color-white {
    color: #FFFFFF;
}

.mobile-search-icon {
    position: absolute;
    top: 10px;
    right: 8px;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.bg-profile {
    /* background: #2A2A2A; */
    background-image: linear-gradient(135deg, #EC008C 0%, #FC6767 100%);
    border-radius: 16px;
}

.bg-collection {
    background: #FFB049 !important;
}

.min-height-33 {
    min-height: 33px;
}

.edit-profile-pic-input {
    width: 182px;
    height: 182px;
    background: #2A2A2A;
    border: 5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 50%;
    background-size: 100% 100%;
}

.w-h-168 {
    width: 168px !important;
    height: 168px !important;
}

.edit-profile-pic-input:hover .d-block-text,
.banner-bg:hover .d-block-btext {
    display: block !important;
}

.edit-prifile-pic-text {
    color: #C51254;
    text-align: center;
    padding-top: 80px;
    font-weight: 700;
}

.ps-ralative {
    position: relative;
}

.color-gray {
    color: #C4C4C4;
}

.border-radius-20 {
    border-radius: 20px;
}

.border-radius-30 {
    border-radius: 30px;
}

.img-hint {
    position: absolute;
    left: 210px;
    width: 210px;
    padding-top: 10px;
    color: #C4C4C4;
    line-height: 21px;
    font-weight: 400;
}

.text-light.text-edit.font-size-32 {
    font-weight: 700;
    margin-top: 20px;
}

.country-btn button {
    border: 1px solid #4C3C4C!important;
    border-radius: 18px;
    height: 39px;
    /* padding: 0 20px!important; */
    font-size: 14px!important;
    font-family: 'Poppins', sans-serif;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none !important;
    width: 70px !important;
    flex: inherit !important;
    background: #4C3C4C !important;
}

.country-btn ul {
    width: 240px;
}

.phone-country {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: 0px;
    border-left: none !important;
    width: 175px !important;
}

.width-246 {
    width: 246px !important;
}

.profile-input {
    background: none;
    color: #FFFFFF;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 5px !important;
    width: 100%;
    border: none !important;
}

.profile-input:focus-visible {
    outline: 0;
}

.btn-submit {
    background: #C51254;
    border-radius: 50px;
    padding: 8px 20px;
    border: none;
}

.desc-text {
    font-size: 16px;
    color: #C4C4C4;
    width: 263px;
}

.color-pink,
.error-msg {
    color: #C51254;
}

.bg-darkmode.edit-profile .color-pink {
    font-weight: 700;
}

.min-height-468 {
    min-height: 468px;
}

.properties-box {
    background: #2A2A2A;
    border-radius: 8px;
}

.w-h-200 {
    width: 200px;
    height: 200px;
}

.height-65 {
    height: 65px;
}

.border-bg {
    background: #C4C4C4;
    border-radius: 8px;
}

.font-w-700 {
    font-weight: 700;
    /* font-family: Nunito Sans !important; */
}

.font-w-400 {
    font-weight: 400;
    /* font-family: Nunito Sans !important; */
}

.w-40 {
    width: 40% !important;
}

.border-2-solid {
    border: 2px solid #3D3D3D;
}

.border-1-solid {
    border: 1px solid #4C3C4C;
}

.links-input-group {
    border-radius: 18px;
}

.links-input-group .input-group:not(:last-child) {
    border-bottom: 1px solid #4C3C4C;
}

.links-input-group input,
.links-input-group .input-group-text {
    background: none !important;
    border: none;
    outline: none !important;
    box-shadow: none !important;
    color: #ffffff !important;
}

.verified-icon {
    position: absolute;
    left: 18px;
}

.bg-media {
    background: linear-gradient(135deg, rgba(170, 7, 107, 0.2) 0%, rgba(97, 4, 95, 0.2) 100%) !important;
}

.claim-box {
    background: linear-gradient(180deg, rgba(180, 180, 180, 0.15) 0%, rgba(69, 69, 69, 0.15) 100%);
    border: 1px solid #4C3C4C;
    border-radius: 20px;
}

.mu-box {
    background: linear-gradient(135deg, rgba(170, 7, 107, 0.5) 0%, rgba(97, 4, 95, 0.5) 100%);
    border-radius: 20px;
}

.background-theame {
    background: #F22E62 !important;
}

.nft-bridging {
    background: #F22E62;
    border-radius: 70px;
    padding: 0 50px;
}

.bridging-wrapper {
    position: absolute;
    bottom: 10px;
    justify-content: center;
    left: 50%;
    transform: translate(-50%);
}

.abcd-circle {
    width: 133px;
    height: 133px;
    background: linear-gradient(135deg, #EC008C 0%, #FC6767 100%);
    border-radius: 50%;
}

.e-circle {
    width: 273px;
    height: 133px;
    background: linear-gradient(135deg, #EC008C 0%, #FC6767 100%);
    border-radius: 90px;
}

.border-row {
    border-radius: 110px;
    border: 2px dashed #EC008C;
    /* background: url('./imgs/border_dashed.svg'); */
    /* background-repeat: no-repeat; */
}

.max-width-160 {
    max-width: 160px;
}

.min-height-56 {
    min-height: 56px;
    vertical-align: middle;
}

.questions .accordion-item {
    background: none !important;
    color: #FFFFFF !important;
}

.questions .accordion-button {
    background: none !important;
    color: #FFFFFF !important;
    /* font-size: 22px !important; */
    font-weight: 700 !important;
}

.questions .accordion-button::after {
    background-image: url('./imgs/plus.svg') !important;
}

.questions .accordion-button:not(.collapsed)::after {
    background-image: url('./imgs/cross.svg') !important;
}

.ques-bg {
    background: linear-gradient(180deg, rgba(180, 180, 180, 0.15) 0%, rgba(69, 69, 69, 0.15) 100%);
    border: 1px solid #4C3C4C;
    border-radius: 20px;
}

.content-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
}

img.mr-2 {
    margin-right: 10px;
}

.gray-textsn span {
    color: #c4c4c4;
}

.bg-darkmode.edit-profile input.profile-input:focus-visible {
    border: 1px solid;
    border-radius: 5px;
}

form.login-info .form-inline label {
    display: block;
    margin-bottom: 5px;
}

form.login-info .form-inline {
    margin-bottom: 15px;
}

.login-forms {
    background: #3D3D3D;
    max-width: 60%;
    margin: 30px 0;
    padding: 50px !important;
    border-radius: 8px;
}

form.login-info .form-inline input {
    height: 44px;
    background-color: transparent !important;
    border: 0;
    padding: 10px;
    border-radius: 5px;
    width: 70%;
    box-shadow: none;
}

form.login-info .form-inline button {
    border: 0;
    background: #c51254;
    height: 44px;
    width: 200px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}

@media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1170px !important;
    }
}


/* banner css */

.menu.border-bottom-2+.menu {
    padding: 30px 0;
}

.row.first-box .col-sm-6.p-5.pb-3 {
    padding-top: 65px !important;
    padding-left: 32px !important;
    padding-right: 35px !important;
}

.first-box .my-5 a.create-link.active {
    padding: 14px 24px !important;
    width: auto !important;
    line-height: 16px;
    font-size: 16px !important;
    /* font-family: Nunito Sans !important; */
    font-weight: 700;
}

.desk-none {
    display: none !important;
}

@media screen and (max-width:1200px) {
    .mobile-margin-0-auto {
        margin: 0 auto !important;
    }
    .search-box .mobile_serch-bar {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        /* border: 2px solid #3D3D3D; */
        text-align: center;
        padding: 0;
        line-height: 40px;
    }
    .search-box .mobile_serch-bar img.search-icon {
        position: static;
    }
    footer.menu.text-white,
    .main-sec-full {
        overflow-x: hidden;
    }
    .home_featured {
        padding: 0 30px !important;
    }
    .bg-darkmode.edit-profile .container,
    .container.p-0,
    .container.px-0 {
        padding-right: 30px !important;
        padding-left: 30px !important;
    }
    .home_banner-section .container {
        padding-right: 30px !important;
        padding-left: 40px !important;
    }
    .tab-none {
        display: none !important;
    }
    .desk-none.tab-block {
        display: block !important;
    }
    .border-bg.product-profile-img {
        display: block;
        width: 250px;
        height: 250px;
        margin: 0 auto;
    }
    .tab-col-w-100 .col-sm-6 {
        width: 100%;
        margin: 10px 0;
    }
    .tabs-links.mt-4 {
        margin-top: 0 !important;
    }
    .properties-box.p-3.me-3 {
        width: calc(100% / 2 - 15px);
        margin: 0 15px 15px 0 !important;
    }
    .properties-box-row {
        flex-wrap: wrap;
    }
}

@media screen and (max-width:992px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 100% !important;
    }
    ul.navbar-nav.me-auto.mb-2.mb-lg-0.create-signin-btn {
        margin: 0 0 0 auto !important;
        display: flex;
        flex-direction: row;
    }
    .login-forms {
        max-width: 100%;
        padding: 40px !important;
    }
    .mobile-center {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media screen and (max-width:768px) {
    .tabs-links .nav-tabs {
        margin: 0 0px 60px !important;
    }
    .add-to-save {
        top: 7px !important;
        right: 20px !important;
        width: 140px !important;
        height: 30px !important;
    }
    .mobile-navbar-collapse {
        position: absolute;
        top: 80px;
        background: black;
        width: 100%;
        left: 0;
    }
    .bridging-wrapper {
        font-size: 16px;
        width: 55%;
    }
    .edit-profile-pic-input {
        width: 90px;
        height: 90px;
        border: 3px solid #FFFFFF;
        border-radius: 50%;
    }
    .edit-prifile-pic-text {
        padding-top: 32px;
        font-size: 14px;
    }
    .height-240 {
        height: 70px;
    }
    footer.menu.text-white,
    .main-sec-full {
        overflow-x: hidden;
    }
    .home_banner-section .col-sm-5 .col-sm-6,
    .home_featured {
        padding: 0 20px !important;
    }
    .home_banner-section .container {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }
    .collection-table {
        width: 1000px !important;
    }
    .bg-darkmode.edit-profile .container,
    .container.p-0,
    .container.px-0 {
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    .viw-call-details .pe-5 {
        width: 33.33% !important;
        margin-bottom: 20px;
        margin-right: 0px;
        padding-right: 0 !important;
        float: left;
    }
    .d-flex.viw-call-details {
        display: block !important;
        margin-top: 20px;
    }
}

@media screen and (max-width:580px) {
    .bid-mobile-100 .col-sm-6 {
        width: 50%;
    }
    .product-pages .mobile-reverce .title+div button {
        padding-top: 7px !important;
    }
    .product-pages .mobile-reverce {
        flex-direction: column-reverse;
    }
    .border-bg.product-profile-img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 auto 30px;
        object-fit: contain;
    }
    .product-pages .mobile-reverce .title {
        font-size: 24px;
    }
    .product-pages .mobile-reverce .title+div+.explore-dot.bg-black {
        margin-top: 3px !important;
    }
    .product-pages .onsel-mob-text-16>div {
        font-size: 16px;
    }
    .product-pages .onsel-mob-text-16>div.me-5 {
        margin-right: 24px !important;
    }
    form.login-info .form-inline input {
        width: 100%;
    }
    .login-forms {
        padding: 20px !important;
    }
}

.dropdown-toggle::after {
    display: none !important;
}

.text-decoration-none {
    text-decoration: none !important
}

.red-btn {
    background: #C51254 !important;
    border-radius: 30px !important;
    color: white !important;
}

.divLoader {
    position: fixed;
    z-index: 9999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);
}

.featured-img {
    height: 100% !important;
    border-radius: 10px;
}

.border-radius-50 {
    border-radius: 50%;
}


/* 
.css-1s2u09g-control,
.css-1pahdxg-control:hover,
.css-1s2u09g-control:hover {
    background: #C51254 !important;
    border: none !important;
}

.css-b62m3t-container [class*="-control"] {
    background: #C51254 !important;
}

.css-14el2xx-placeholder,
.css-6j8wv5-Input,
.css-qt7jg1-singleValue {
    color: #FFFFFF !important;
} */


/* 
.css-4ljt47-MenuList {
    background: #2A2A2A !important;
} */

.css-vdoepe-option {
    background: #383838 !important;
}

.color-red {
    color: red;
}

.bg-options {
    background-repeat: no-repeat;
    background-size: 100%;
}

.terms-condition li {
    padding-top: 30px;
}

button:focus {
    box-shadow: none !important;
}

.twit-inp {
    justify-content: space-between;
    align-items: baseline;
}

.banner-bg {
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}

.modal-content {
    background: rgb(240, 240, 240);
}

.text-create {
    color: #d7c7b2 !important;
}


/* 
.create-menu{
  min-width: 600px;
} */


/* 
.bg-gradient{
  background-image: url('./imgs/home_bg.svg');
} */

.create-menu .properties-box {
    min-width: 180px;
    max-width: 235px;
    cursor: pointer;
}

.create-menu .properties-box div img {
    height: 100%;
    object-fit: none;
}


/* 
.create-signin-btn .dropdown-menu{
  background: rgb(42, 42, 42);
} */

.create-menu .properties-box,
.menu-modal .modal-content {
    /* background: rgb(42, 42, 42) !important; */
    background: #240D24 !important;
}

.create-menu .properties-box:hover {
    background: rgb(240, 240, 240) !important;
}


/* 
.border-2-solid {
    border: 1px solid #d7c7b2 !important;
} */

.border-2-solid {
    border: 1px solid #4C3C4C !important;
}

.font-family-clash-display {
    font-family: 'Clash Display', sans-serif;
}

.text-lighgray {
    color: rgba(255, 255, 255, 0.5);
}

.text-green {
    color: #03DB80;
}

.border-bottom-1-gray {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.height-75vh {
    height: 80vh;
    overflow: auto;
}

.break-word {
    white-space: break-spaces;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-break {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.height-100 {
    min-height: 100px !important;
}

.text-gray-font-12 {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
    font-size: 12px;
}

.hr-line {
    mix-blend-mode: normal;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    transform: matrix(1, 0, 0, -1, 0, 0);
    height: 0px !important;
    margin: 0.5rem 0 !important;
}

.like-btn {
    background: #000000;
    mix-blend-mode: normal;
    border-radius: 16px;
    font-size: 12px !important;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 16px !important;
    margin: 0px !important;
    z-index: 999;
}

.buy-now-link {
    background: linear-gradient(135deg, #EC008C 0%, #FC6767 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    -webkit-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
}

.music-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    border: none !important;
    padding: 0px 16px !important;
}

.bg-size-100 {
    background-size: 100% 100% !important;
}

.up-icon {
    padding-bottom: 5px;
}

.vh-50 {
    height: 60vh;
}

.max-height-450 {
    max-height: 450px;
    overflow: hidden;
}

.max-height-350 {
    max-height: 350px;
    overflow: hidden;
}

.search-menu .dropdown-menu {
    min-width: 275px;
    max-height: 500px;
    overflow: auto;
}

.search-menu .btn-dark,
.search-menu .btn-dark:hover,
.search-menu .btn-dark:hover,
.search-menu .btn-check:active+.btn-dark:focus,
.search-menu .btn-check:checked+.btn-dark:focus,
.search-menu .btn-dark.active:focus,
.search-menu .btn-dark:active:focus,
.search-menu .show>.btn-dark.dropdown-toggle:focus {
    /* .search-menu .btn-dark, .search-menu .btn-dark:hover, .search-menu .btn-dark:active{ */
    background: none !important;
    border: none;
}

.ant-upload-list {
    display: none !important;
}

.ant-upload-select {
    width: 100%;
}

.price-field input[type=number].profile-input {
    padding-left: 35px!important;
}


/* Light Mode css */


/* 
.white-content .edit-profile-pic-input{
  background: #F3F3F3 !important;
} */

.white-content .navbar.sticky-top,
.white-content .search-box input,
.white-content .bg-darkmode,
.white-content .img-preview-box {
    background: #FFFFFF !important;
}

.white-content .nav-link,
.white-content .text-light,
.white-content .login-link,
.white-content .text-white,
.white-content .bid-btn,
.white-content .follow-btn,
.modal-body .nav-link {
    color: #232323 !important;
}

.white-content .text-light-mode {
    color: #FFFFFF !important;
}

.white-content .dot {
    background: #232323;
}

.white-content .search-box input,
.white-content .login-link,
.white-content .toggle-link,
.white-content .bid-btn,
.white-content .help,
.white-content .img-preview-box,
.white-content .copy-btn,
.white-content .border-2-solid,
.white-content .more-btn,
.white-content .up-btn,
.white-content .follow-btn {
    border: 1px solid #C4C4C4;
}

.white-content .border-bottom-2 {
    border-bottom: 2px solid #C4C4C4;
}

.white-content .border-top-2 {
    border-top: 2px solid #C4C4C4;
}

.white-content .first-box,
.white-content .top-sec-box,
.white-content .properties-box,
.modal-body .properties-box {
    background: #F3F3F3;
    border: 1px solid #C4C4C4;
}

.white-content .bg-profile {
    background: #F3F3F3;
}

.white-content .edit-profile-pic-input {
    border: 5px solid #C4C4C4;
}

.white-content .auther-desc,
.white-content .color-gray,
.white-content .profile-input,
.white-content .desc-text,
.white-content .img-preview-box,
.white-content label.sc-bdvvtL.iciELI .ebdQkY>span {
    color: #717171;
}

.white-content .tabs-links .nav-tabs .nav-link.active,
.white-content .tabs-links .nav-tabs .nav-link:hover,
.modal-body .tabs-links .nav-tabs .nav-link.active,
.modal-body .tabs-links .nav-tabs .nav-link:hover {
    background-color: #FFFFFF !important;
}

.white-content .tabs-links .nav-tabs {
    border-bottom: 1px solid #C4C4C4 !important;
}

.white-content .collection-table.table>:not(caption)>*>* {
    background-color: #F3F3F3;
}

.white-content .collection-table.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #FFFFFF;
}

.white-content .table-dark,
.white-content .table-striped>tbody>tr:nth-of-type(odd)>* {
    color: #232323;
}

.white-content tbody,
.white-content td,
.white-content tfoot,
.white-content th,
.white-content thead,
.white-content tr {
    border-color: #C4C4C4;
}

.white-content .more-btn img,
.white-content .up-btn img,
.white-content .explore-dot img,
.white-content .copy-btn img {
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

.white-content .bg-black,
.white-content .product-pages .explore-dot.bg-black {
    border: 1px solid #C4C4C4;
    background: none !important;
}

.white-content .desk-none.d-block-992 span.navbar-toggler-icon {
    filter: grayscale(100%);
}

.storage-modal {
    background-color: rgba(33, 37, 41, 0.4);
}

.setting-icon {
    width: 46px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 50%;
    position: absolute;
    right: 30px;
    top: 20px;
    font-size: 20px;
}


/* slider css */

.landingpartnetr {
    text-align: center;
    margin-bottom: 100px;
    padding: 60px 0 60px;
    background-color: #200020;
}

.landingpartnetr h1 {
    font-size: 54px;
    color: #FFFFFF;
    font-weight: 800;
}

.relative {
    position: relative;
}

.sepraterBac {
    padding: 0 80px;
}

.sepraterBac:before {
    position: absolute;
    top: 60px;
    left: 0;
    background: linear-gradient(270deg, rgba(32, 0, 32, 0) 0%, #200020 50.18%);
    content: "";
    width: 183px;
    height: 92px;
    z-index: 9;
}

.sepraterBac:after {
    position: absolute;
    top: 60px;
    right: 0;
    background: linear-gradient(270deg, rgba(32, 0, 32, 0) 0%, #200020 50.18%);
    content: "";
    width: 183px;
    height: 92px;
    z-index: 9;
    transform: rotate(-180deg);
}

.sepraterBac img {
    width: 190px!important
}

.uod {
    padding-bottom: 150px;
}

.uod h2 {
    font-size: 54px;
    color: #FFFFFF;
    font-weight: 800;
}

.uod h3 {
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 700;
    padding: 24px 0 0;
    margin: 0;
}

.uod p {
    padding: 9px 0 0;
    margin: 0;
    font-size: 16px;
    line-height: 21px;
    color: #FFFFFF;
}

.sliderimg {
    position: relative;
    overflow: hidden;
    border-radius: 14px !important;
    cursor: pointer;
}

.sliderimg img {
    border-radius: 14px;
}

.sliderimg:after {
    background: linear-gradient(360deg, #F22E62 0%, rgba(242, 46, 98, 0) 100%);
    opacity: 0.6;
    border-radius: 14px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
}

.pressNews {
    padding: 120px 0 60px;
}

.pressNews h4 {
    font-size: 51px;
    color: #FFFFFF;
    font-weight: 800;
    text-align: center;
}

.nsli {
    position: relative;
}

.add-to-save {
    background: #F22E62 !important;
    mix-blend-mode: normal;
    border-radius: 23px;
    outline: none;
    outline: none;
    width: 174px;
    height: 46px;
    border: none;
    color: #ffffff;
    position: absolute;
    top: 30px;
    right: 40px;
}

.btn-width-inherit {
    width: inherit !important;
}

.top-4 {
    top: 4px !important;
}

.add-to-save:hover {
    background: #1c1f23 !important;
    transition: 0.5s;
}

.nsli:after {
    background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%);
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
}

.tags {
    position: absolute;
    top: 21px;
    left: 30px;
    z-index: 9;
}

.tags a {
    background-color: #2AAF77;
    color: #FFF;
    padding: 4px 28px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 700;
}

.tags a:hover {
    background: #F22E62;
    color: #FFF;
}

.newstitle {
    position: absolute;
    left: 30px;
    right: 100px;
    bottom: 12px;
    color: #FFF;
    font-size: 18px;
    line-height: 22px;
    font-weight: 700;
    padding: 0 0px 0 0;
    z-index: 9;
}

.newstitle a {
    color: #FFF;
}

.newstitle a:hover {
    color: #FFF;
}

.align-item {
    align-items: inherit!important;
}

@media screen and (min-width:1500px) {
    .container-fluid-lgscreen {
        max-width: 1450px;
    }
}

.banroimage {
    -webkit-animation: spin 15s linear infinite;
    -moz-animation: spin 15s linear infinite;
    animation: spin 15s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width:700px) {
    .banroimage {
        width: 250px;
    }
}


/* MINT NFT page css start here */

.titletop {
    font-size: 34px;
    font-weight: 800;
    margin-bottom: 40px;
    font-family: 'Poppins', sans-serif;
}

.titleb {
    font-size: 18px;
    color: #FFF;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
}

.pydescription {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    margin: 0 0 34px;
}

.img-hover-zoom {
    overflow: hidden;
}

.img-hover-zoom img {
    transition: transform .5s ease;
}

.img-hover-zoom:hover img {
    transform: scale(1.5);
}

.paleft .upload-text {
    font-size: 18px;
    color: #FFF;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
}

.paleft .text-white {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
}

.paleft .file-upload label {
    background: linear-gradient(180deg, rgba(180, 180, 180, 0.15) 0%, rgba(69, 69, 69, 0.15) 100%);
    border: 1px dashed #4C3C4C;
    border-radius: 12px;
    max-width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 320px;
    flex-flow: column;
}

.paleft .file-upload div {
    display: block;
    flex-grow: inherit;
}

.paleft .file-upload label svg {
    height: 100px;
    width: 100px;
}

.paleft .file-upload label path {
    fill: #FFF;
}

.paleft .file-upload .file-upload-cosef {
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

h3.formtitle {
    font-size: 18px;
    color: #FFF;
    font-weight: 800;
    font-family: 'Poppins', sans-serif;
}

.height-80 {
    height: 80px !important;
}


/* 
#react-select-2-input {
    border: none !important;
} */

.paleft textarea,
.paleft input[type=text],
.paleft input[type=email],
.paleft input[type=date],
.paleft input[type=number],
.paleft input[type=tel],
.paleft input[type=url] {
    border: 1px solid #4C3C4C!important;
    border-radius: 18px;
    height: 39px;
    padding: 0 20px!important;
    /* color: rgba(255, 255, 255, 0.5)!important; */
    font-size: 14px!important;
    font-family: 'Poppins', sans-serif !important;
}

.two {
    display: flex;
    justify-content: space-between;
}

.two .create-col {
    width: 48%;
    background: #240D24!important;
    background-image: none!important;
    mix-blend-mode: normal;
    border-radius: 10px;
    padding: 8px 0 6px !important;
}

.two .create-col p {
    color: #FFF;
    padding: 0!important;
    margin: 0!important;
    line-height: 27px;
}

.two .pt-4 {
    width: 48%;
    padding: 15px 0 0!important;
}

.two .select-dropdown-main [class*="-control"] {
    /* background: #C51254 !important; */
    min-height: 66px;
    border: 1px solid #F22E62!important;
    border-radius: 10px!important;
    background: none!important;
}


/* 
.two .select-dropdown-main input {
    border: none !important;
} */

.two .css-1okebmr-indicatorSeparator {
    display: none;
}

.paleft .py-3.labelform {
    font-size: 12px!important;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 4px!important;
}

.paleft .btn-submit.text-light.bg-darkmode.border-2-solid {
    background: #4C3C4C!important;
    border-radius: 18px!important;
    border: 0!important;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

.nftright .no-img-txt {
    width: 90%;
}

.nftright .no-img-txt img {
    width: 100%
}

.nftright .no-img-txt {
    transform: translate(-50%, -55%)!important;
}

.catU img {
    width: 32px!important;
    height: 32px;
}

.catU img.validate {
    width: 15px !important;
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 27px;
    /* background: #FFF; */
    border-radius: 20px;
    /* background-size: 24px !important; */
}

.min-height-320 {
    min-height: 320px;
    height: auto;
}

.banner-text img {
    width: 100%;
}


/* .navbar-nav.menu-right{
    display: flex;
    padding-left: inherit;
    margin-bottom: 0;
    list-style: none;
    justify-content: flex-end;
    flex: inherit;
} */

.res-claim-box .form-control {
    border: 0;
}

.w-9 {
    width: 9px;
}

.padding-vertical-120 {
    padding: 120px 0 100px!important;
}

.banner-text-mid {
    font-size: 54px;
    font-style: normal;
    font-weight: 700;
    line-height: 68px;
}

.banner-text-mid span {
    font-size: 34px;
    text-shadow: 0 0 1px #000000;
}

.create-1.create-new {
    background: none !important;
    top: -40px;
    right: 0;
    height: 27px;
}

.robo-content.spacing-left-right {
    padding: 0px 40px 30px;
}

.vertical-align-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.banner-text-mid img {
    width: 100%;
}


/* 
.select-dropdown-main .css-4ljt47-MenuList {
    background: #281228!important;
    border: 1px solid #4C3C4C;
    border-radius: 12px;
    padding: 8px;
} */


/* 
.select-dropdown-main [id*=-listbox] {
    background: #281228!important;
    border: 1px solid #4C3C4C;
    border-radius: 12px;
}

.select-dropdown-main [id*=-listbox] div:first-child {
    border-radius: 12px;
}

[id*=-listbox] div[class*="-option"] {
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    font-family: 'Poppins', sans-serif !important;
    padding: 9px 16px;
    background: none;
    margin: 2px 0!important;
}

[id*=-listbox] div[class*="-option"]:hover,
[id*=-listbox] div[class*="-option"]:active,
[id*=-listbox] div[class*="-option"]:focus {
    background: #200020;
} */


/* [id*=-listbox] div[class*="-option"]:after {
    background: url(imgs/verified.png) no-repeat left top;
    content: "";
    margin: 2px 0 0 8px;
    width: 13px;
    height: 13px;
} */


/* 
.select-dropdown-main .css-26l3qy-menu {
    background: none!important;
} */


/* 
.select-dropdown-main div[class*="-option"] {
    border-radius: 6px;
    font-weight: 700;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    padding: 9px 16px;
    background: none;
    margin: 2px 0!important;
}

.select-dropdown-main div[class*="-option"]:after {
    background: url(imgs/verified.png) no-repeat left top;
    content: "";
    margin: 2px 0 0 8px;
    width: 13px;
    height: 13px;
}

.select-dropdown-main div[class*="-option"]:hover {
    background: #200020;
}

.select-dropdown-main .css-6j8wv5-Input {
    border: 0!important;
} */

.footer-new:first-child {
    display: flex;
    align-items: center;
    vertical-align: top;
}

.footer-new h2 {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
}

.modal-dialog1 {
    border: 1px solid #4C3C4C;
    border-radius: 12px;
}

.modal-dialog1 .modal-content {
    background-color: #281228;
    border-radius: 12px;
}

.footer-new input[type=text],
.footer-new input[type=email] {
    background: #281228;
    width: 301px;
    border-radius: 24px;
    height: 48px;
    border: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #8D8C8D;
    font-family: 'Poppins', sans-serif;
    padding: 0 16px;
}

.footer-new ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.footer-new ul li {
    padding: 0;
    margin: 0;
}

.footer-new ul li a {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #8D8C8D;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
}

.footer-new ul li a:hover {
    color: #FFF;
}

input,
img,
a,
a img {
    outline: none;
}

.login-with-near h2 {
    font-size: 3rem;
    /* 54px; */
    color: #FFF;
    font-family: 'Clash Display', sans-serif;
}

.login-with-near p {
    font-family: 'Poppins';
}

.login-with-near p a {
    width: 430px;
    height: 60px;
    line-height: 60px;
    background: #FFFFFF;
    border-radius: 80px;
    display: inline-block;
    font-family: 'Poppins';
    font-size: 16px;
    color: #121212;
    font-weight: 700;
    position: relative;
}

.login-with-near p a img {
    position: absolute;
    left: 7%;
    top: 38%;
}

.login-with-near p a.pink-button {
    background: #F22E62;
    color: #FFF;
}

.login-with-near p a.pink-button img {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
}

.activity-area {
    width: 100%;
    background: linear-gradient(180deg, rgba(180, 180, 180, 0.15) 0%, rgba(69, 69, 69, 0.15) 100%);
    border: 1px solid #4C3C4C;
    border-radius: 16px;
}

.about-title-font {
    font-size: 4rem !important;
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 700 !important;
}

.about-section-height {
    /* height: calc(100vh - 98px); */
    height: 70vh;
    overflow: hidden;
    /* min-height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-tabs {
    width: 208px;
    height: 39px;
    background: #281228;
    border-radius: 40px;
    justify-content: space-around;
    align-items: center;
}

.about-tabs .nav-link.active {
    background: #200020 !important;
    border-radius: 40px !important;
}

.about-social-icon-box {
    width: 50px;
    height: 50px;
    background: linear-gradient(180deg, rgba(180, 180, 180, 0.15) 0%, rgba(69, 69, 69, 0.15) 100%);
    border: 1px solid #4C3C4C;
    border-radius: 50%;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-team .about-social-icon-box {
    width: 29px;
    height: 29px;
    font-size: 12px;
}

.about-profile-box {
    width: 120px;
    height: 120px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    margin: 0 auto;
}

.about-profile-box img {
    border-radius: 50%;
}

.team-title {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
}

.team-subtitle {
    font-family: 'Clash Display';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #F22E62;
}

.file-progress-bar {
    position: fixed;
    top: 100px;
    z-index: 99999999999999;
    width: 100%;
    left: 0px;
}

.activity-area ul {
    list-style-type: none;
}

.activity-area ul li h4 {
    font-size: 12px;
    font-family: 'Poppins';
    margin: 0 0 17px;
}

.activity-area ul li p img.nearlogo {
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
}

.activity-area ul li p a {
    color: #F22E62;
}

.activity-area ul li h2 {
    height: 18px;
    line-height: 18px;
    background: #E5C3D1;
    border-radius: 18px;
    padding: 0 9px;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Poppins';
    display: inline-block;
}

.activity-area ul li p.gray-color {
    color: #8D8C8D;
}

@media screen and (max-width:1370px) {
    .row {
        margin-left: 0!important;
        margin-right: 0!important;
    }
    .search-icon.res-search {
        position: inherit!important;
    }
}

@media screen and (max-width:768px) {
    .abcd-circle {
        width: 70px;
        height: 70px;
    }
    .e-circle {
        width: 100px;
        height: 60px;
    }
    .abcd-circle.font-size-24,
    .e-circle.font-size-24 {
        font-size: 12px;
    }
    .d-flex.res-display-grid {
        display: grid!important;
        grid-template-columns: 33.33% 33.33% 33.33%!important;
    }
    .d-flex.res-display-grid .max-width-160 {
        max-width: 100%;
    }
    .claim-box.res-claim-box {
        margin: 20px 0 0;
    }
    .claim-box.res-claim-box .top-sec-box {
        max-height: inherit;
        min-height: inherit;
    }
    .banner-text {
        font-size: 40px!important;
        line-height: 50px!important;
    }
    .banner-desc {
        font-size: 18px!important;
        line-height: 27px!important;
    }
    .res-padding-right-left-10 {
        padding: 0 10px;
    }
}

@media screen and (max-width:640px) {
    .flex-direction-column-mobile {
        flex-direction: column;
    }
    .border-bottom-1-mobile {
        border-right: none !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    /* .d-flex.res-display-grid {
        display: grid!important;
        grid-template-columns: 50% 50%!important;
    } */
    .d-flex.res-display-grid {
        display: block !important;
    }
    .d-flex.res-display-grid .px-3 {
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }
    .font-size-34.res-font-size-22 {
        font-size: 22px;
    }
    .number-boxes-bg {
        margin: 0;
    }
    .res-margin-bottom-20 {
        margin-bottom: 20px;
    }
    .claim-box.res-claim-box {
        padding: 0!important;
    }
    .claim-box.res-claim-box .top-sec-box {
        margin: 20px 0!important;
    }
    .font-size-34.res-font-size-28 {
        font-size: 28px;
        margin-top: 20px;
    }
    .res-padding-right {
        padding: 28px 20px 10px 0!important;
    }
    .nft-bridging {
        padding: 0 10px;
    }
    .banner-text-mid span {
        font-size: 24px;
    }
    .res-padding-right {
        padding: 10px 20px 10px 0 !important;
    }
}